import apiHelper from '../../utils/apiHelpers';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import './ProductsDetails.css';
import Modal from 'react-modal';
import Tooltip from '../common/Tooltip';
import SelectProductCategory from '../common/selects/SelectProductCategory';

export default class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      productDocument: null,
      isNew: props.match.params.id === 'new',
      productId: props.match.params.id,
      modalIsOpen: false,
      product: {
        weight: '',
        code: '',
        description: '',
        category: '',
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    apiHelper
      .getProduct(this.state.productId)
      .then(response => {
        this.setState({
          product: response.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this.deleteProduct.bind(this);
    this.submitProduct.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChange(event) {
    const target = event.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const product = this.state.product;
    product[name] = value;
    this.setState({
      product: product,
    });
  }

  async deleteProduct() {
    apiHelper.deleteProduct(this.state.productId);
    this.props.history.push('/product');
  }

  async submitProduct(event) {
    event.preventDefault();
    let productId = this.state.productId;
    this.setState({ isLoading: true });
    try {
      //If products already exists, update it. If not, create it
      const product = this.state.product;

      if (product.weight) {
        product.weight = product.weight.replace(',', '.');
      }
      if (this.state.isNew) {
        await apiHelper.postProduct(product);
      } else {
        await apiHelper.patchProduct(productId, product);
      }

      this.setState({ isLoading: false });
      this.props.history.push('/product');
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.message;
      }

      this.setState({
        errorMessage: errorMessage,
      });
    }
  }

  render() {
    let title = 'Crear Producto';
    let deleteButton = false;

    if (!this.state.isNew) {
      title = 'Producto';
    }
    if (!this.state.isNew) {
      deleteButton = (
        <button
          className="btn btn-danger pull-right some-top-air"
          type="button"
          onClick={this.openModal.bind(this)}>
          Eliminar <span className="fa fa-trash-alt"></span>
        </button>
      );
    }

    return (
      <div key={this.state.productId} className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container mt-3">
          <h1 className="text-position-title">
            <i className="fas fa-clipboard-list"></i>
            <span> {title} </span>
            {deleteButton}
          </h1>

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal.bind(this)}
            className="modal-dialog fadeInDown">
            <div className="modal-content text-center">
              <div className="modal-header">
                <span>
                  <h3 className="text-center">
                    ¿Está seguro de querer eliminar este producto?
                  </h3>
                </span>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModal.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <button
                  className="button-delete-modal btn btn-success some-top-air"
                  type="button"
                  onClick={this.deleteProduct.bind(this)}>
                  {' '}
                  Si
                </button>
                <button
                  className="button-delete-modal btn btn-danger some-top-air"
                  type="button"
                  onClick={this.closeModal.bind(this)}>
                  {' '}
                  No
                </button>
              </div>
            </div>
          </Modal>

          <div className="row  ">
            <div className="col-md-4 creation-info position p-3">
              <div className="form-group">
                <label htmlFor="code"> Código </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="code"
                  className="form-control"
                  placeholder=" Código "
                  value={this.state.product.code}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description"> Descripción</label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="description"
                  className="form-control"
                  placeholder=" Descripción "
                  value={this.state.product.description}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="category"> Categoría</label>
                <SelectProductCategory
                  onChange={this.handleChange.bind(this)}
                  name="category"
                  className="form-control"
                  placeholder=" Categoría "
                  value={this.state.product.category}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="weight"> Peso</label>
                <input
                  data-tip
                  onChange={this.handleChange.bind(this)}
                  name="weight"
                  data-for={this.state.product.id + 'weight'}
                  className="form-control"
                  placeholder=" peso "
                  value={this.state.product.weight}
                  required
                />
                <Tooltip
                  id={this.state.product.id + 'weight'}
                  tooltipText="Usar ' . ' como separador de decimales"
                />
              </div>
              <div className="form-group">
                <label htmlFor="api_creation_status"> Estado en API</label>
                <input
                  data-tip
                  onChange={this.handleChange.bind(this)}
                  name="api_creation_status"
                  data-for={this.state.product.id + 'api_creation_status'}
                  className="form-control"
                  placeholder=" Sin estado "
                  value={this.state.product.api_creation_status || null}
                  disabled
                />
                <Tooltip
                  id={this.state.product.id + 'api_creation_status'}
                  tooltipText={
                    this.state.product.api_creation_reason ||
                    'Estado en API externa Andreani'
                  }
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            {function () {
              return (
                <button
                  className="button-submit btn btn-primary my-5"
                  onClick={this.submitProduct.bind(this)}
                  type="submit"
                  disabled={this.state.isLoading}>
                  {this.state.isLoading ? 'Guardando...' : 'Guardar'}{' '}
                  <i className="fas fa-save"></i>
                </button>
              );
            }.bind(this)()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
