import React from 'react';
import './RequestsFilters.css';
import SelectRequestOrderNumber from '../common/selects/SelectRequestOrderNumber';
import SelectProvider from '../common/selects/SelectProvider';
import SelectDates from '../common/selects/SelectDates';
import SelectPickingState from '../common/selects/SelectPickingState';
import SelectWeight from '../common/selects/SelectWeight';
import SelectPostalCodeState from '../common/selects/SelectPostalCodeState';
import SelectSentToProvider from '../common/selects/SelectSentToProvider';
import SelectWithGift from '../common/selects/SelectWithGift';
import { FiltersCacheContext } from '../common/FiltersCacheContext';
import SelectCancelled from '../common/selects/SelectCancelled';
import moment from 'moment';
import apiHelpers from '../../utils/apiHelpers';
import SelectPickUpState from '../common/selects/SelectPickUpState';
import config from '../../config/config';
import SelectByCaseNumber from '../common/selects/SelectByCaseNumber';
import SelectWithReturn from '../common/selects/SelectWithReturn';
import SelectReturnState from '../common/selects/SelectReturnState';
import SelectByDocumentNumber from '../common/selects/SelectByDocumentNumber';
import SelectDeliveryState from '../common/selects/SelectDeliveryState';
import { reports } from '../../config/config';
import { withAlert } from 'react-alert';

class RequestsFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  openFilters(e) {
    e.preventDefault();

    this.setState({
      openFilters: true,
    });
  }

  closeFilters(e) {
    e.preventDefault();

    this.setState({
      openFilters: false,
    });
  }

  getFiltersObject() {
    let sentToProvider = null;
    if (this.state.sent_to_provider != null && this.state.sent_to_provider)
      sentToProvider = 1;
    if (this.state.sent_to_provider != null && !this.state.sent_to_provider)
      sentToProvider = 0;
    let cancelled = null;
    if (this.state.cancelled != null && this.state.cancelled) cancelled = 1;
    if (this.state.cancelled != null && !this.state.cancelled) cancelled = 0;
    let with_gift = null;
    if (this.state.with_gift != null && this.state.with_gift) with_gift = 1;
    if (this.state.with_gift != null && !this.state.with_gift) with_gift = 0;
    let with_return = null;
    if (this.state.with_return != null && this.state.with_return)
      with_return = 1;
    if (this.state.with_return != null && !this.state.with_return)
      with_return = 0;
    let solved = null;
    if (this.state.solved != null && this.state.solved) solved = 1;
    if (this.state.solved != null && !this.state.solved) solved = 0;
    return {
      where: {
        order_number: this.state.order_number,
        'provider.id': this.state['provider.id'],
        state_picking: this.state.state_picking,
        state_weight: this.state.state_weight,
        state_postal_code: this.state.state_postal_code,
        sent_to_provider: sentToProvider,
        min_import_date: moment(this.state.min_import_date)
          .startOf('day')
          .toISOString(),
        max_import_date: this.state.max_import_date
          ? moment(this.state.max_import_date).endOf('day').toISOString()
          : this.state.max_import_date,
        sent_to_provider_date: this.state.sent_to_provider_date,
        last_picked_date: this.state.last_picked_date,
        weight_hours: this.state.weight_hours,
        cancelled: cancelled,
        pick_up_state: this.state.pick_up_state,
        with_gift: with_gift,
        case_number: this.state.case_number,
        document_number: this.state.document_number,
        with_return: with_return,
        solved: solved,
        delivery_state: this.state.delivery_state,
        api_creation_status: this.state.api_creation_status,
      },
    };
  }

  getInitialState() {
    return {
      order_number: null,
      'provider.id': null,
      state_picking: null,
      state_weight: null,
      state_postal_code: null,
      sent_to_provider: null,
      min_import_date: null,
      max_import_date: null,
      weight_hours: null,
      last_picked_date: null,
      sent_to_provider_date: null,
      cancelled: null,
      openFilters: false,
      currentUser: null,
      pick_up_state: null,
      with_gift: null,
      case_number: null,
      document_number: null,
      with_return: this.state?.currentUser?.role.id === 6 ? true : null,
      solved: null,
      delivery_state: null,
    };
  }

  componentWillUnmount() {
    const [cacheFilters, setCacheFilters] = this.context;
    setCacheFilters({ ...cacheFilters, ['RequestsFilters']: this.state });
  }

  async componentDidMount() {
    await this.getUser();
    const cachedState = this.context[0]['RequestsFilters'];
    if (cachedState) {
      this.setState({ ...cachedState }, () => {
        this.onFiltersSubmit();
      });
    } else {
      this.onFiltersSubmit();
    }
  }

  async getUser() {
    let userResponse = await apiHelpers.getCurrentUser();
    this.setState({
      currentUser: userResponse.data,
      with_return: userResponse.data.role.id === 6 ? true : null,
    });
  }

  clearMax() {
    if (this.state.max_import_date) {
      this.setState({ max_import_date: null });
    }
  }

  clearMin() {
    if (this.state.min_import_date) {
      this.setState({ min_import_date: null });
    }
  }

  onFiltersChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });

    // if (name === 'max_import_date') {
    //   // Exception case to reset older date limit
    //   this.setState({
    //     max_import_date: value,
    //     min_import_date: null,
    //   });
    // } else {
    //   this.setState({ [name]: value });
    // }
  }

  onFiltersRefresh() {
    this.getUser();
    this.setState(this.getInitialState(), () => {
      this.props.loadData(this.getFiltersObject());
    });
    this.setState({ openFilters: true });
  }

  onFiltersSubmit() {
    const monthsLimit = reports?.monthsLimit || 4;
    let min_import_date = this.state.min_import_date
      ? moment(this.state.min_import_date).startOf('day')
      : moment().startOf('day');
    let max_import_date = this.state.max_import_date
      ? moment(this.state.max_import_date).endOf('day')
      : moment().endOf('day');
    const monthsDiff = max_import_date.diff(min_import_date, 'months', true);

    if (monthsDiff > monthsLimit) {
      this.props.alert.error(
        `El periodo seleccionado supera los ${monthsLimit} meses.`,
      );
    } else {
      this.props.loadData(this.getFiltersObject());
    }
  }

  render() {
    return (
      <div className="row d-flex justify-content-center request-filters">
        {this.state.openFilters && (
          <div className="row align-content-center request-filters col-sm-12">
            {/* First ROW */}
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectRequestOrderNumber
                name="order_number"
                placeholder="Número de orden"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.order_number}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectDates
                onChange={this.onFiltersChange.bind(this)}
                name="min_import_date"
                value={this.state.min_import_date}
                placeholder="Importados desde"
              />{' '}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectDates
                onChange={this.onFiltersChange.bind(this)}
                name="max_import_date"
                value={this.state.max_import_date}
                placeholder="Importados hasta"
              />{' '}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectDeliveryState
                onChange={this.onFiltersChange.bind(this)}
                name="delivery_state"
                value={this.state.delivery_state}
                placeholder="Estado de entrega"
              />{' '}
            </div>

            {/* Second ROW */}
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectPostalCodeState
                name="state_postal_code"
                placeholder="Estado del código postal"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.state_postal_code}
                clearable={true}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectWeight
                name="state_weight"
                placeholder="Estado del peso"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.state_weight}
                clearable={true}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectSentToProvider
                name="sent_to_provider"
                placeholder="Enviado a proveedor"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.sent_to_provider}
                clearable={true}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectPickingState
                onChange={this.onFiltersChange.bind(this)}
                name="state_picking"
                placeholder="Expedición"
                value={this.state.state_picking}
              />
            </div>

            {/* Third ROW */}
            <div
              className={`col-lg-${
                config.giftsFeature && !config.giftsFeature.active ? 4 : 3
              } col-md-6 col-sm-12`}>
              {!this.state.currentUser?.pickUpProvider ? (
                <SelectProvider
                  name="provider.id"
                  placeholder="Proveedor"
                  onChange={this.onFiltersChange.bind(this)}
                  value={this.state['provider.id']}
                />
              ) : (
                <input
                  name="provider.id"
                  className="form-control"
                  placeholder=" Proveedor "
                  value={this.state.currentUser?.pickUpProvider?.name}
                  disabled
                />
              )}
            </div>
            <div className={`col-lg-3 col-md-6 col-sm-12`}>
              <SelectCancelled
                name="cancelled"
                placeholder="Cancelados"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.cancelled}
                clearable={true}
              />
            </div>
            <div className={`col-lg-3 col-md-6 col-sm-12`}>
              <SelectPickUpState
                name="pick_up_state"
                placeholder="Estado de Pick-Up"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.pick_up_state}
                clearable={true}
              />
            </div>
            {config.giftsFeature && config.giftsFeature.active && (
              <div className={`col-lg-3 col-md-6 col-sm-12`}>
                <SelectWithGift
                  name="with_gift"
                  placeholder="Regalo"
                  onChange={this.onFiltersChange.bind(this)}
                  value={this.state.with_gift}
                  clearable={true}
                />
              </div>
            )}

            {/* Forth ROW */}
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectByCaseNumber
                name="case_number"
                placeholder="Número de caso"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.case_number}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              {this.state.currentUser?.role.id !== 6 ? (
                <SelectWithReturn
                  name="with_return"
                  placeholder="Devolución asociada"
                  onChange={this.onFiltersChange.bind(this)}
                  value={this.state.with_return}
                />
              ) : (
                <input
                  name="with_return"
                  className="form-control"
                  value={'Con devolución asociada'}
                  disabled
                />
              )}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectReturnState
                name="solved"
                placeholder="Estado de devolución"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.solved}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <SelectByDocumentNumber
                name="document_number"
                placeholder="Número de documento"
                onChange={this.onFiltersChange.bind(this)}
                value={this.state.document_number}
              />
            </div>
          </div>
        )}
        {this.state.openFilters ? (
          <div className="container row">
            <div className="col-sm-12 col-lg-4">
              <button
                disabled={this.props.isLoadingData}
                type="button"
                className="btn btn-primary w-100 my-1"
                onClick={this.onFiltersSubmit.bind(this)}>
                Aplicar filtros
                <i className="ml-3 fas fa-search"></i>
              </button>
            </div>
            <div className="col-sm-12 col-lg-4">
              <button
                disabled={this.props.isLoadingData}
                type="button"
                className="btn btn-danger w-100 my-1"
                onClick={this.onFiltersRefresh.bind(this)}>
                Reiniciar filtros
                <i className="ml-3 fas fa-sync"></i>
              </button>
            </div>
            <div className="col-sm-12 col-lg-4">
              <button
                disabled={this.props.isLoadingData}
                onClick={this.closeFilters.bind(this)}
                className="btn btn-warning w-100 my-1"
                type="button">
                Ocultar filtros
                <i className="ml-3 fas fa-angle-double-up"></i>
              </button>
            </div>
          </div>
        ) : (
          <div className="request-filters col-sm-12">
            <div className="col-sm-12">
              <button
                onClick={this.openFilters.bind(this)}
                className="btn btn-warning btn-block"
                type="button">
                Mostrar filtros <i className="ml-1 fas fa-search"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

RequestsFilters.contextType = FiltersCacheContext;
export default withAlert()(RequestsFilters);
