import React, { Component } from 'react';
import moment from 'moment';
import apiHelper from '../../utils/apiHelpers';
import config from '../../config/config';
import { FiltersCacheContext } from '../common/FiltersCacheContext';

class ProvidersBreakDownBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRequestOf: props.data,
    };
  }

  onClickRequestsProcessed(value) {
    if (apiHelper.isAdmin() || apiHelper.isSupervisor()) {
      value.cancelled = false;
      const [cacheFilters, setCacheFilters] = this.context;
      setCacheFilters({ ...cacheFilters, ['RequestsFilters']: value });
      this.props.history.push('/request');
    }
  }

  render() {
    return (
      <>
        <div className="row overlay margin-top">
          <div
            className={`col-sm-12 col-lg-${
              config.countryVersion?.backLogDashboard ? 3 : 4
            }`}>
            <div className="category-hidden creation-info postal-code p-3 mr-1">
              <div className={apiHelper.isAdmin() ? 'clickable' : ''}>
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      'provider.id': this.state.totalRequestOf.provider_id,
                    })
                  }>
                  <i className="fas fa-file-import text-success ml-3 "></i>
                  <span>
                    <b> Pedidos importados </b>
                  </span>
                </div>
              </div>
              <div className={apiHelper.isAdmin() ? 'clickable' : ''}>
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      state_weight: 'SP',
                      'provider.id': this.state.totalRequestOf.provider_id,
                    })
                  }>
                  <i className="fas fa-weight text-danger ml-3 "></i>
                  <span>
                    <b> Pedidos sin pesar </b>
                  </span>
                </div>
              </div>
              {config.validatePicking.active && (
                <div className={apiHelper.isAdmin() ? 'clickable' : ''}>
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        state_picking: 'SP',
                        'provider.id': this.state.totalRequestOf.provider_id,
                      })
                    }>
                    <i className="fas fa-cubes text-danger ml-3 "></i>
                    <span>
                      <b> Pedidos sin expedición </b>
                    </span>
                  </div>
                </div>
              )}
              <div className={apiHelper.isAdmin() ? 'clickable' : ''}>
                <div
                  onClick={() => {
                    config.countryVersion.currentCountry == 'AR'
                      ? this.onClickRequestsProcessed({
                          api_creation_status: ['en espera', 'RECHAZADO'],
                          'provider.id': this.state.totalRequestOf.provider_id,
                        })
                      : this.onClickRequestsProcessed({
                          sent_to_provider: '0',
                          'provider.id': this.state.totalRequestOf.provider_id,
                        });
                  }}
                  hidden={this.state.totalRequestOf.provider_isWarehouse}>
                  <i className="fa fa-envelope text-danger ml-3"></i>
                  <span>
                    <b> Pedidos no enviados</b>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              config.countryVersion?.backLogDashboard
                ? 'col-sm-12 col-lg-1'
                : 'col-sm-12 col-lg-2'
            }>
            <div className=" creation-info postal-code p-3 ">
              <div className=" text-center ">
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      'provider.id': this.state.totalRequestOf.provider_id,
                      min_import_date: moment().startOf('day').toDate(),
                      max_import_date: moment().endOf('day').toDate(),
                    })
                  }
                  className={apiHelper.isAdmin() ? 'clickable' : ''}>
                  <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                    <span className="mr-3  category-hidden-lg ">
                      <i className="fas fa-file-import text-success  category-hidden-lg "></i>
                      <span className=" category-hidden-lg mr-2">
                        {' '}
                        Pedidos importados{' '}
                      </span>
                    </span>
                    {
                      this.state.totalRequestOf
                        .totalImportedRequestsOfOneProvider
                    }
                  </b>
                </div>
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      state_weight: 'SP',
                      'provider.id': this.state.totalRequestOf.provider_id,
                      min_import_date: moment().startOf('day').toDate(),
                      max_import_date: moment().endOf('day').toDate(),
                    })
                  }
                  className={apiHelper.isAdmin() ? 'clickable' : ''}>
                  <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                    <span className="mr-4  category-hidden-lg ">
                      <i className="fas fa-weight text-danger category-hidden-lg "></i>
                      <span className=" category-hidden-lg mr-3">
                        {' '}
                        Pedidos sin pesar{' '}
                      </span>
                    </span>
                    {
                      this.state.totalRequestOf
                        .totalRequestWeightlessTodayOfOneProvider
                    }
                  </b>
                </div>

                {config.validatePicking.active && (
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        state_picking: 'SP',
                        'provider.id': this.state.totalRequestOf.provider_id,
                        min_import_date: moment().startOf('day').toDate(),
                        max_import_date: moment().endOf('day').toDate(),
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" category-hidden-lg ">
                        <i className="fas fa-cubes text-danger  category-hidden-lg"></i>
                        <span className=" category-hidden-lg mr-1">
                          {' '}
                          Pedidos sin expedición:
                        </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestNotPickedTodayOfOneProvider
                      }
                    </b>
                  </div>
                )}

                {config.countryVersion.currentCountry === 'AR' ? ( // Andreani exception
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        api_creation_status: 'en espera',
                        'provider.id': this.state.totalRequestOf.provider_id,
                        min_import_date: moment().startOf('day').toDate(),
                        max_import_date: moment().endOf('day').toDate(),
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}
                    hidden={this.state.totalRequestOf.provider_isWarehouse}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" mr-3 category-hidden-lg ">
                        <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                        <span> Pedidos no enviados: </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestQueuedToSendToProviderTodayOfOneProvider
                      }
                    </b>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        sent_to_provider: false,
                        'provider.id': this.state.totalRequestOf.provider_id,
                        min_import_date: moment().startOf('day').toDate(),
                        max_import_date: moment().endOf('day').toDate(),
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}
                    hidden={this.state.totalRequestOf.provider_isWarehouse}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" mr-3 category-hidden-lg ">
                        <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                        <span> Pedidos no enviados: </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestNotSentOfOneProviderToday
                      }
                    </b>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-12">
            <div className="creation-info postal-code p-3">
              <div className=" text-center ">
                <div>
                  <b>---</b>
                </div>

                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      state_weight: 'SP',
                      'provider.id': this.state.totalRequestOf.provider_id,
                      max_import_date: moment()
                        .subtract(1, 'days')
                        .endOf('day')
                        .toDate(),
                    })
                  }
                  className={apiHelper.isAdmin() ? 'clickable' : ''}>
                  <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                    <span className="mr-4  category-hidden-lg ">
                      <i className="fas fa-weight text-danger category-hidden-lg "></i>
                      <span className=" category-hidden-lg mr-3">
                        {' '}
                        Pedidos sin pesar{' '}
                      </span>
                    </span>
                    {config.countryVersion?.backLogDashboard
                      ? this.state.totalRequestOf
                          .totalRequestWeightlessPendingOnTimeOfOneProvider
                      : this.state.totalRequestOf
                          .totalRequestWeightlessPendingOfOneProvider}
                  </b>
                </div>

                {config.validatePicking.active && (
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        state_picking: 'SP',
                        'provider.id': this.state.totalRequestOf.provider_id,
                        max_import_date: moment()
                          .subtract(1, 'days')
                          .endOf('day')
                          .toDate(),
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" category-hidden-lg ">
                        <i className="fas fa-cubes text-danger  category-hidden-lg"></i>
                        <span className=" category-hidden-lg mr-1">
                          {' '}
                          Pedidos sin expedición:
                        </span>
                      </span>
                      {config.countryVersion?.backLogDashboard
                        ? this.state.totalRequestOf
                            .totalRequestNotPickedPendingOnTimeOfOneProvider
                        : this.state.totalRequestOf
                            .totalRequestNotPickedOfOneProvider}
                    </b>
                  </div>
                )}
                {config.countryVersion.currentCountry === 'AR' ? ( // Andreani exception
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        api_creation_status: [
                          'en espera',
                          'PENDIENTE',
                          'RECHAZADO',
                        ],
                        'provider.id': this.state.totalRequestOf.provider_id,
                        max_import_date: moment()
                          .subtract(1, 'days')
                          .endOf('day')
                          .toDate(),
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}
                    hidden={this.state.totalRequestOf.provider_isWarehouse}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" mr-3 category-hidden-lg ">
                        <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                        <span> Pedidos no enviados: </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestQueuedToSendToProviderOnTimeOfOneProvider
                      }
                    </b>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        sent_to_provider: false,
                        'provider.id': this.state.totalRequestOf.provider_id,
                        max_import_date: moment()
                          .subtract(1, 'days')
                          .endOf('day')
                          .toDate(),
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}
                    hidden={this.state.totalRequestOf.provider_isWarehouse}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" mr-3 category-hidden-lg ">
                        <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                        <span> Pedidos no enviados: </span>
                      </span>
                      {config.countryVersion?.backLogDashboard
                        ? this.state.totalRequestOf
                            .totalRequestNotSentPendingOnTimeOfOneProvider
                        : this.state.totalRequestOf
                            .totalRequestNotSentPendingOfOneProvider}
                    </b>
                  </div>
                )}
              </div>
            </div>
          </div>

          {config.countryVersion?.backLogDashboard && (
            <div className="col-lg-2 col-sm-12">
              <div className="creation-info postal-code p-3">
                <div className=" text-center ">
                  <div>
                    <b>---</b>
                  </div>

                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        'provider.id': this.state.totalRequestOf.provider_id,
                        max_import_date: moment()
                          .subtract(2, 'days')
                          .endOf('day')
                          .toDate(),
                        state_weight: 'SP',
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className="mr-4  category-hidden-lg ">
                        <i className="fas fa-weight text-danger category-hidden-lg "></i>
                        <span className=" category-hidden-lg mr-3">
                          {' '}
                          Pedidos sin pesar{' '}
                        </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestWeightlessPendingDelayedOfOneProvider
                      }
                    </b>
                  </div>

                  {config.validatePicking.active && (
                    <div
                      onClick={() =>
                        this.onClickRequestsProcessed({
                          'provider.id': this.state.totalRequestOf.provider_id,
                          max_import_date: moment()
                            .subtract(2, 'days')
                            .endOf('day')
                            .toDate(),
                          state_picking: 'SP',
                        })
                      }
                      className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                        <span className=" category-hidden-lg ">
                          <i className="fas fa-cubes text-danger  category-hidden-lg"></i>
                          <span className=" category-hidden-lg mr-1">
                            {' '}
                            Pedidos sin expedición:
                          </span>
                        </span>
                        {
                          this.state.totalRequestOf
                            .totalRequestNotPickedPendingDelayedOfOneProvider
                        }
                      </b>
                    </div>
                  )}

                  {config.countryVersion.currentCountry === 'AR' ? ( // Andreani exception
                    <div
                      onClick={() =>
                        this.onClickRequestsProcessed({
                          'provider.id': this.state.totalRequestOf.provider_id,
                          api_creation_status: [
                            'en espera',
                            'PENDIENTE',
                            'RECHAZADO',
                          ],
                          max_import_date: moment()
                            .subtract(2, 'days')
                            .endOf('day')
                            .toDate(),
                        })
                      }
                      className={apiHelper.isAdmin() ? 'clickable' : ''}
                      hidden={this.state.totalRequestOf.provider_isWarehouse}>
                      <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                        <span className=" mr-3 category-hidden-lg ">
                          <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                          <span> Pedidos no enviados: </span>
                        </span>
                        {
                          this.state.totalRequestOf
                            .totalRequestQueuedToSendToProviderDelayedOfOneProvider
                        }
                      </b>
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        this.onClickRequestsProcessed({
                          'provider.id': this.state.totalRequestOf.provider_id,
                          sent_to_provider: false,
                          max_import_date: moment()
                            .subtract(2, 'days')
                            .endOf('day')
                            .toDate(),
                        })
                      }
                      className={apiHelper.isAdmin() ? 'clickable' : ''}
                      hidden={this.state.totalRequestOf.provider_isWarehouse}>
                      <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                        <span className=" mr-3 category-hidden-lg ">
                          <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                          <span> Pedidos no enviados: </span>
                        </span>
                        {
                          this.state.totalRequestOf
                            .totalRequestNotSentPendingDelayedOfOneProvider
                        }
                      </b>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-2 col-sm-12">
            <div className="creation-info postal-code p-3">
              <div className=" text-center ">
                <div>
                  <b>---</b>
                </div>

                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      state_weight: 'PV',
                      weight_hours: moment().toDate(),
                      'provider.id': this.state.totalRequestOf.provider_id,
                    })
                  }
                  className={apiHelper.isAdmin() ? 'clickable' : ''}>
                  <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                    <span className="mr-4  category-hidden-lg ">
                      <i className="fas fa-weight text-danger category-hidden-lg "></i>
                      <span className=" category-hidden-lg mr-3">
                        {' '}
                        Pedidos sin pesar{' '}
                      </span>
                    </span>
                    {
                      this.state.totalRequestOf
                        .totalRequestWeightlessOfOneProvider
                    }
                  </b>
                </div>

                {config.validatePicking.active && (
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        state_picking: 'PV',
                        last_picked_date: moment().toDate(),
                        'provider.id': this.state.totalRequestOf.provider_id,
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" category-hidden-lg ">
                        <i className="fas fa-cubes text-danger  category-hidden-lg"></i>
                        <span className=" category-hidden-lg mr-1">
                          {' '}
                          Pedidos sin expedición:
                        </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestNotPickedOfOneProvider
                      }
                    </b>
                  </div>
                )}

                {config.countryVersion.currentCountry === 'AR' ? ( // Andreani exception
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        api_creation_status: 'ACEPTADO',
                        sent_to_provider_date: moment().toDate(),
                        'provider.id': this.state.totalRequestOf.provider_id,
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}
                    hidden={this.state.totalRequestOf.provider_isWarehouse}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" mr-3 category-hidden-lg ">
                        <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                        <span> Pedidos no enviados: </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestQueuedToSendToProviderTodayDoneOfOneProvider
                      }
                    </b>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        sent_to_provider: true,
                        sent_to_provider_date: moment().toDate(),
                        'provider.id': this.state.totalRequestOf.provider_id,
                      })
                    }
                    className={apiHelper.isAdmin() ? 'clickable' : ''}
                    hidden={this.state.totalRequestOf.provider_isWarehouse}>
                    <b className={apiHelper.isAdmin() ? 'clickable' : ''}>
                      <span className=" mr-3 category-hidden-lg ">
                        <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                        <span> Pedidos no enviados: </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestNotSentOfOneProvider
                      }
                    </b>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row overlay margin-top ">
          <div className="justify-content-center row provider-name provider col-lg-10">
            {!config.countryVersion?.noPostalCode && (
              <div
                className={
                  apiHelper.isAdmin()
                    ? 'clickable col-lg-5 postal-code col-sm-12 creation-info p-3'
                    : 'col-lg-5 col-sm-12 postal-code creation-info p-3'
                }
                onClick={() =>
                  this.onClickRequestsProcessed({
                    state_postal_code: 'CD',
                    'provider.id': this.state.totalRequestOf.provider_id,
                  })
                }>
                <span>
                  <i className="fa fa-mail-bulk ml-3 text-danger "></i>
                  <b> Pedidos con error de código postal</b>
                </span>
                <div className="pull-right">
                  <b>
                    {
                      this.state.totalRequestOf
                        .totalRequestWithWrongPostalCodeOfOneProvider
                    }
                  </b>
                </div>
              </div>
            )}

            <div
              className={`${apiHelper.isAdmin() && 'clickable'} ${
                config.countryVersion?.noPostalCode ? 'col-sm-12' : 'col margin'
              } margin provider postal-code creation-info p-3`}
              onClick={() =>
                this.onClickRequestsProcessed({
                  state_weight: 'PD',
                  'provider.id': this.state.totalRequestOf.provider_id,
                })
              }>
              <span>
                <i className="fa fa-weight text-warning ml-3"></i>
                <b> Pedidos con diferencia de peso</b>
              </span>
              <div className="pull-right">
                <b>
                  {
                    this.state.totalRequestOf
                      .totalRequestWithWrongWeightOfOneProvider
                  }
                </b>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ProvidersBreakDownBoard.contextType = FiltersCacheContext;

export default ProvidersBreakDownBoard;
