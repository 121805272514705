import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import Pagination from '../common/Pagination';
import './Providers.css';
import Modal from 'react-modal';
import formatter from '../../utils/formatter';
import AlertMessage from '../common/AlertMessage';
import config from '../../config/config';

export default class Providers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      modalIsOpen: false,
      count: 0,
      newProviders: null,
      pageSize: 25,
      currentPage: 1,
      loading: false,
      mailToOne: false,
      providerToMail: null,
      alert: false,
      alertSuccess: '',
      isOneProvider: false,
      requests: [],
      max_import_date: null,
      min_import_date: null,
      submitEnabled: true,
      orderErrors: [],
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeWarning() {
    this.setState({ alertSuccess: '', alert: false });
  }

  handleChangePage(pageOfItems) {
    this.setState({ currentPage: pageOfItems }, () => {
      this.getProviders();
    });
  }

  getNumberOfPages() {
    return Math.ceil(this.state.count / this.state.pageSize);
  }

  async setQueryForOneProvider(value) {
    this.setState(
      {
        max_import_date: value.where.max_import_date,
        min_import_date: value.where.min_import_date,
      },
      () => {
        this.getRequestsForOneProvider();
      },
    );
  }

  async setQueryForAllProvider(value) {
    this.setState(
      {
        max_import_date: value.where.max_import_date,
        min_import_date: value.where.min_import_date,
      },
      () => {
        this.getRequestsForAllProvider();
      },
    );
  }

  async getProviders(filter = []) {
    const { filters, currentPage, pageSize } = this.state;

    try {
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [response, providersCount] = await Promise.all([
        await apiHelper.getProviders(filtersWithPaging),
        await apiHelper.getProvidersCount(filters),
      ]);

      this.setState({
        providers: response.data,
        count: providersCount.data.count,
      });
    } catch (e) {
      console.log('Error loading providers');
    }
  }

  onChangeFile(event) {
    event.preventDefault();
    this.setState({ newProviders: event.target.files[0] });
  }

  async sendEmailForOneProvider() {
    try {
      await this.setState({
        submitEnabled: false,
        orderErrors: [],
        loading: true,
      });
      let filter = {
        where: {
          id: this.state.providerToMail.id,
        },
      };
      let data = { provider: this.state.providerToMail.id };
      if (this.state.import_date) data.import_date = this.state.import_date;

      let response = await apiHelper.postSendEmail(data);
      if (response.data.errors) {
        const errors = response.data.errors;
        this.setState({ orderErrors: errors, loading: false });
      }

      if (
        this.state.requests.length > 0 &&
        this.state.orderErrors.length <= 0
      ) {
        await this.setState({
          alertSuccess:
            'Pedido enviado exitosamente al proveedor: ' +
            this.state.providerToMail.name,
          alert: true,
          submitEnabled: true,
          loading: false,
        });
      } else {
        await this.setState({ submitEnabled: true });
      }
      this.closeModal();
    } catch (error) {
      this.closeModal();
      await this.setState({
        errorMessage: error.response.data ? error.response.data : error.message,
        submitEnabled: true,
      });
    }
  }

  async senEmailForAllProvider() {
    try {
      await this.setState({ submitEnabled: false });

      let data = {};

      if (this.state.max_import_date)
        data.max_import_date = this.state.max_import_date;
      if (this.state.min_import_date)
        data.min_import_date = this.state.min_import_date;

      await apiHelper.postSendEmail(data);

      if (this.state.requests.length > 0) {
        await this.setState({
          alertSuccess: 'Pedidos enviados exitosamente a todos los proveedores',
          alert: true,
          submitEnabled: true,
        });
      } else {
        await this.setState({ submitEnabled: true });
      }
      this.closeModal();
    } catch (error) {
      this.closeModal();
      await this.setState({
        errorMessage: error.response.data ? error.response.data : error.message,
        submitEnabled: true,
      });
    }
  }

  async getRequestsForOneProvider() {
    try {
      let providerId = this.state.providerToMail.id;

      let filter = {
        where: [
          {
            sent_to_provider: 0,
            state_weight: 'PV',
            state_postal_code: 'CV',
            cancelled: false,
            api_creation_status: 'null',
          },
          {
            sent_to_provider: 0,
            state_weight: 'PV',
            state_postal_code: 'SV',
            cancelled: false,
            api_creation_status: 'null',
          },
        ],
      };

      if (!!config.validatePicking.active) {
        let newWhere = filter.where.map(element => {
          return {
            ...element,
            last_picked_date: 'notNull',
          };
        });
        filter = { where: newWhere };
      }

      if (
        this.state.max_import_date !== null &&
        this.state.max_import_date !== ''
      ) {
        filter.where[0].max_import_date = this.state.max_import_date;
        filter.where[1].max_import_date = this.state.max_import_date;
      }
      if (
        this.state.min_import_date !== null &&
        this.state.min_import_date !== ''
      ) {
        filter.where[0].min_import_date = this.state.min_import_date;
        filter.where[1].min_import_date = this.state.min_import_date;
      }

      let response = await apiHelper.getRequests(filter);
      response.data = response.data.filter(
        request => request.provider.id === providerId,
      );
      this.setState({ requests: response.data });
      if (response.data.length <= 0) {
        this.setState({ submitEnabled: false });
      } else {
        this.setState({ submitEnabled: true });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async getRequestsForAllProvider() {
    try {
      let filter = {
        where: [
          {
            sent_to_provider: 0,
            state_weight: 'PV',
            state_postal_code: 'CV',
            api_creation_status: 'null',
          },
          {
            sent_to_provider: 0,
            state_weight: 'PV',
            state_postal_code: 'SV',
            api_creation_status: 'null',
          },
        ],
      };
      /*  if (this.state.max_import_date !== null && this.state.max_import_date !== ''){
             filter.where[0].max_import_date = this.state.max_import_date;
             filter.where[1].max_import_date = this.state.max_import_date;
             }
             if (this.state.min_import_date !== null && this.state.min_import_date !== ''){
             filter.where[0].min_import_date = this.state.min_import_date;
             filter.where[1].min_import_date = this.state.min_import_date;
             }*/

      if (!!config.validatePicking.active) {
        let newWhere = filter.where.map(element => {
          return {
            ...element,
            last_picked_date: 'notNull',
          };
        });
        filter = { where: newWhere };
      }

      let response = await apiHelper.getRequests(filter);
      this.setState({ requests: response.data });
      if (response.data.length <= 0) {
        this.setState({ submitEnabled: false });
      } else {
        this.setState({ submitEnabled: true });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async openModalMailToOneProvider(provider) {
    try {
      await this.setState({
        providerToMail: provider,
        isOneProvider: true,
        mailToOne: true,
        modalIsOpen: true,
      });
      const response = await this.getRequestsForOneProvider();
      this.setState({
        requests: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async openModalMailToAllProvider() {
    try {
      const response = await this.getRequestsForAllProvider();
      this.setState({
        modalIsOpen: true,
        mailToOne: false,
        requests: response.data,
      });
    } catch (errors) {
      console.log(errors);
    }
  }

  componentDidMount() {
    this.getProviders();
  }

  render() {
    let buttonMailProvider = '';
    let alert = '';
    let filters = '';
    let alertOfRequest = '';

    /*   if (this.state.isOneProvider === true) {

         filters = <ProviderFilters loadData={this.setQueryForOneProvider.bind(this)}/>
         } else {
         filters = <ProviderFilters loadData={this.setQueryForAllProvider.bind(this)}/>
         }
         */
    if (this.state.requests.length <= 0) {
      alertOfRequest = (
        <AlertMessage
          type="warning"
          title="No hay pedidos validados para enviar al proveedor hasta la fecha"
        />
      );
    }

    if (this.state.alert === true) {
      alert = (
        <AlertMessage
          type="success"
          title="Envío a proveedor exitoso"
          onClose={this.closeWarning.bind(this)}>
          <td>
            <p>{this.state.alertSuccess}</p>
          </td>
        </AlertMessage>
      );
    }

    if (this.state.errorMessage) {
      alert = (
        <AlertMessage
          type="error"
          title="Error enviando a proveedor"
          onClose={this.closeWarning.bind(this)}>
          <td>
            <p>{this.state.errorMessage}</p>
          </td>
        </AlertMessage>
      );
    }

    if (this.state.mailToOne) {
      buttonMailProvider = (
        <button
          type="button"
          className="btn btn-warning pull-right mb-3 "
          onClick={this.sendEmailForOneProvider.bind(this)}
          disabled={!this.state.submitEnabled}>
          Enviar mail{' '}
          <i
            className={`fas fa-envelope-open-text ${
              this.state.loading ? 'icon-spin' : ''
            }`}></i>
        </button>
      );
    } else {
      buttonMailProvider = (
        <button
          type="button"
          className="btn btn-warning pull-right "
          onClick={this.senEmailForAllProvider.bind(this)}
          disabled={!this.state.submitEnabled}>
          Enviar mail <i className="fas fa-envelope-open-text"></i>
        </button>
      );
    }

    let ordersAlert;
    if (this.state.orderErrors.length) {
      ordersAlert = (
        <AlertMessage
          type="warning"
          title="Error al crear pedido"
          onClose={this.closeWarning.bind(this)}>
          {this.state.orderErrors.map((orderError, i) => {
            return (
              <tr key={i}>
                <td>
                  <p>
                    {'Pedido '}
                    {orderError.orderNumber}:
                    <b> {`${orderError.status}, ${orderError.reason}`}</b>
                  </p>
                </td>
              </tr>
            );
          })}
        </AlertMessage>
      );
    }

    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />

        <div className="container">
          <h1 className="row text-nuskin">
            <div className="col-lg-6 title col-sm-12">
              <i className="fas fa-truck mt-3"></i>{' '}
              <span>Listado de proveedores</span>
            </div>

            <div className="row ml-1 margin-import col-lg-6 col-sm-12 ">
              <div className=" col-sm-12 col-lg-8">
                <button
                  type="button"
                  className="btn w-100 btn-warning pull-right some-top-air"
                  onClick={this.openModalMailToAllProvider.bind(this)}>
                  Enviar mail a todos los proveedores
                  <i className="fas fa-envelope-open-text ml-1"></i>
                </button>
              </div>
              <div className=" col-sm-12 col-lg-4">
                <Link to={`${process.env.PUBLIC_URL}/provider/new`}>
                  <button
                    type="button"
                    className="btn w-100 btn-success pull-right some-air">
                    Agregar <i className="fas fa-plus-square ml-1"></i>
                  </button>
                </Link>
              </div>
            </div>
          </h1>
          {alert}
          {ordersAlert}
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal.bind(this)}
            className="modal-dialog-email fadeInDown modal-dialog"
            contentLabel="Mail a proveedores">
            <div className="modal-content text-center">
              <div className="modal-header d-flex">
                <span className="px-1">Pedidos a enviar a proveedor</span>
                <span className="px-1">-</span>
                <span className="px-1">
                  Total de pedidos:{' '}
                  <strong>{this.state.requests.length}</strong>
                </span>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModal.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form method="post" action="" encType="multipart/form-data">
                  <div
                    className="table-responsive overflow-auto my-2"
                    style={{ maxHeight: '300px' }}>
                    {buttonMailProvider}
                    {filters}
                    {alertOfRequest}
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Número de pedido</th>
                          <th>Numero de entrega</th>
                          <th>Fecha de importación</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.requests.map(request => {
                          return (
                            <tr key={request.id}>
                              <td>{request.order_number}</td>
                              <td>{request.delivery_number}</td>
                              <td>
                                {formatter.formatDate(request.import_date)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Proveedor</th>
                  <th scope="col">Email</th>
                  <th scope="col">Enviar mail</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody>
                {this.state.providers.map(provider => {
                  const actionColumn = (
                    <td>
                      <div className="text-center">
                        <Link
                          to={`${process.env.PUBLIC_URL}/provider/${provider.id}`}>
                          <button
                            type="button"
                            className="btn btn-primary btn-circle">
                            <span className="fa fa-edit"></span>
                          </button>
                        </Link>
                      </div>
                    </td>
                  );
                  const mailButton = (
                    <td>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.openModalMailToOneProvider(provider);
                          }}>
                          <i className="fas fa-envelope-open-text"></i>
                        </button>
                      </div>
                    </td>
                  );

                  return (
                    <tr key={provider.id}>
                      <td>{provider.name}</td>
                      <td>{provider.email}</td>
                      {mailButton}
                      {actionColumn}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-center">
          <Pagination
            onChangePage={this.handleChangePage.bind(this)}
            currentPage={this.state.currentPage}
            totalPages={this.getNumberOfPages()}
          />
        </div>
        <Footer />
      </div>
    );
  }
}
