import { Link } from 'react-router-dom';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import Pagination from '../common/Pagination';
import './Requests.css';
import Modal from 'react-modal';
import formatter from '../../utils/formatter';
import Button from '@material-ui/core/Button';
import Tooltip from '../common/Tooltip';
import RequestsFilters from './RequestsFilters';
import ProductRequestValidation from './productRequestValidation';
import config from '../../config/config';
import AlertMessage from '../common/AlertMessage';
import apiHelpers from '../../utils/apiHelpers';
import Loading from '../common/Loading';
import ProgressBar from '../common/ProgressBar';
const { io } = require('socket.io-client');

export default class Requests extends React.Component {
  download = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      modalIsOpen: false,
      duplicatesModalIsOpen: false,
      count: 0,
      newRequests: null,
      pageSize: 25,
      currentPage: 1,
      loading: false,
      importErrors: null,
      importSuccess: false,
      dropDown: false,
      CSVdata: '',
      boxOptions: [],
      importAlert: false,
      importAlertErrors: null,
      importErrorWithAlert: null,
      haveDocumentImport: false,
      message: false,
      loadingRequests: false,
      currentUser: null,
      verifyDeliveryNro: true,
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidMount() {
    this.getBoxData();
    if (
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      this.setState({ message: true });
    }
  }

  closeWarning() {
    this.setState({ importErrors: null, dropDown: false });
  }

  closeSuccess() {
    this.setState({ importSuccess: false });
  }

  closeMessageSentProvider() {
    this.setState({ message: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeduplicatesModal() {
    this.setState({ duplicatesModalIsOpen: false });
  }

  openDropDown() {
    this.setState({ dropDown: true });
  }

  openImportAlert() {
    this.setState({ importAlert: true });
  }

  closeImportAlert() {
    this.setState({ importAlert: false });
  }

  handleChangePage(pageOfItems) {
    this.setState({ currentPage: pageOfItems }, () => {
      this.getRequests();
    });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters, currentPage: 1 }, () => {
      this.getRequests();
    });
  }

  async getBoxData() {
    const boxResponse = await apiHelpers.getProducts({
      where: { category: 'box' },
    });
    this.setState({ boxOptions: boxResponse.data });
  }

  async getRequests() {
    this.setState({ loadingRequests: true });
    const { filters, currentPage, pageSize, count } = this.state;

    try {
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      let user = await apiHelpers.getCurrentUser();
      if (user.data.pickUpProvider) {
        filtersWithPaging.where['provider.id'] = user.data.pickUpProvider.id;
      }
      const requestsResponse = await apiHelpers.getRequests(filtersWithPaging);
      const requests = requestsResponse.data;
      this.setState({
        requests: requests,
        count: 0,
        loadingRequests: false,
        currentUser: user.data,
      });

      const requestsCountResponse = await apiHelpers.getRequestsCount(filters);
      const requestsCount = requestsCountResponse.data.count;
      this.setState({
        requests: requests,
        count: requestsCount,
        loadingRequests: false,
        currentUser: user.data,
      });
    } catch (e) {
      console.log('Error loading requests', e);
      this.setState({
        requests: [],
        count: 0,
        loadingRequests: false,
      });
    }
  }

  getNumberOfPages() {
    return Math.ceil(this.state.count / this.state.pageSize);
  }

  onChangeFile(event) {
    event.preventDefault();
    this.setState({
      newRequests: event.target.files[0],
      haveDocumentImport: true,
    });
  }

  handleDeliverySwitch(event) {
    this.setState({
      verifyDeliveryNro: event.target.checked,
    });
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async importRequests() {
    let socket;
    while (!socket) {
      try {
        socket = io(config.backend.wsUrl, {
          path: config.backend.wsPath,
          transports: ['websocket'],
        });
      } catch (e) {
        socket = undefined;
        this.delay(500);
      }
    }
    this.setState({ loading: true, haveDocumentImport: false });
    apiHelpers
      .importRequests(this.state.newRequests, this.state.verifyDeliveryNro)
      .then(async response => {
        socket.close();
        if (response.data.success) {
          this.closeModal();
          this.setState({ importErrors: null, loading: false });
          this.setState({ importSuccess: true });
        } else {
          this.closeModal();
          const errors = config.countryVersion.noPostalCode
            ? response.data.errors.filter(err => err.type !== 'postalCode')
            : response.data.errors;
          const importErrors = errors.filter(importErrors => {
            return importErrors.importAlert === false;
          });
          if (importErrors.length > 0) {
            await this.setState({
              importErrors: importErrors,
              loading: false,
              duplicatesModalIsOpen:
                config.countryVersion.noPostalCode &&
                !!importErrors.find(err => err.type === 'duplicate'),
            });
          }

          const importErrorWithAlert = errors.filter(importErrors => {
            return importErrors.importAlert === true;
          });

          if (importErrorWithAlert.length > 0) {
            await this.setState({
              importErrorWithAlert: importErrorWithAlert,
              loading: false,
            });
            this.openImportAlert(this);
          }
        }
        this.getRequests();
      });
    socket.emit('upload', this.state.newRequests, status => {
      console.log(status);
    });
  }

  async redirectOpenFile(requestId) {
    const url = await apiHelpers.getRequestFileURL(requestId);
    window.open(url.data, '_blank', 'noopener,noreferrer');
  }

  render() {
    let modalImportSuccess = '';
    let importAlert = '';

    if (this.state.importErrors !== null) {
      if (this.state.importErrors.length < 6) {
        modalImportSuccess = (
          <AlertMessage
            type="error"
            title="Error de importación"
            onClose={this.closeWarning.bind(this)}>
            {this.state.importErrors.map(importErrors => {
              return (
                <tr key={importErrors.line}>
                  <td>
                    <p>
                      {'Error en la linea '}
                      {importErrors.line}:<b> {importErrors.message}</b>
                    </p>
                  </td>
                </tr>
              );
            })}
          </AlertMessage>
        );
      } else {
        if (this.state.dropDown === false) {
          modalImportSuccess = (
            <AlertMessage
              type="warning"
              title={`${this.state.importErrors.length} Errores de importación`}
              onClose={this.closeWarning.bind(this)}>
              <Button
                onClick={() => {
                  this.openDropDown(this);
                }}
                variant="contained"
                color="primary"
                className="btn btn-success pull-right some-top-air"
                size="small">
                ver todos
              </Button>
            </AlertMessage>
          );
        } else {
          modalImportSuccess = (
            <AlertMessage
              type="error"
              title="Error de importación"
              onClose={this.closeWarning.bind(this)}>
              {this.state.importErrors.map(importErrors => {
                return (
                  <tr key={importErrors.line}>
                    <td>
                      <p>
                        {'Error en la linea '}
                        {importErrors.line}:<b> {importErrors.message}</b>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </AlertMessage>
          );
        }
      }
    }

    if (this.state.importErrorWithAlert !== null) {
      if (
        this.state.importErrorWithAlert.length > 0 &&
        this.state.importAlert == true
      ) {
        importAlert = (
          <AlertMessage
            type="error"
            title="Error de importación"
            onClose={this.closeImportAlert.bind(this)}>
            {this.state.importErrorWithAlert.map(importErrors => {
              return (
                <tr key={importErrors.line}>
                  <td>
                    <p>
                      {'Error en la linea '}
                      {importErrors.line}:<b> {importErrors.message}</b>
                    </p>
                  </td>
                </tr>
              );
            })}
          </AlertMessage>
        );
      }
    }

    if (this.state.importErrors === null && this.state.importSuccess === true) {
      modalImportSuccess = (
        <AlertMessage
          type="success"
          title="Importación"
          onClose={this.closeSuccess.bind(this)}>
          <strong>Todos los pedidos se importaron correctamente</strong>
        </AlertMessage>
      );
    }
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />

        <div className="container">
          <h1 className=" row text-nuskin">
            <div className="col-lg-6 title col-sm-12">
              <i className="fas fa-box-open mt-3"></i>{' '}
              <span>Listado de pedidos</span>
            </div>

            {this.state.currentUser === null
              ? null
              : this.state.currentUser?.role.id !== 4 &&
                this.state.currentUser?.role.id !== 7 && (
                  <div className="row margin-import col-lg-6 col-sm-12 ">
                    <div className=" col-sm-12 col-lg-4">
                      <Link to={`${process.env.PUBLIC_URL}/request/new`}>
                        <button
                          disabled={this.state.loadingRequests}
                          type="button"
                          className=" w-100 btn btn-success pull-right some-air">
                          Agregar <i className="fas fa-plus-square"></i>
                        </button>
                      </Link>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                      <Link to={`${process.env.PUBLIC_URL}/requests-reports`}>
                        <button
                          disabled={this.state.loadingRequests}
                          type="button"
                          className=" w-100 btn btn-success pull-right some-air">
                          Reporte <i className="fas fa-chart-bar"></i>
                        </button>
                      </Link>
                    </div>

                    <div className="col-sm-12 col-lg-4">
                      <button
                        disabled={this.state.loadingRequests}
                        type="button"
                        className="w-100 btn btn-success pull-right some-air"
                        onClick={this.openModal.bind(this)}>
                        Importar <i className="fas fa-file-import"></i>
                      </button>
                    </div>
                  </div>
                )}
          </h1>
          <RequestsFilters
            loadData={this.handleChangeFilters.bind(this)}
            isLoadingData={this.state.loadingRequests}
          />
          {this.props.location.state !== null && this.state.message && (
            <AlertMessage
              type="success"
              size="smal"
              title={`${this.props.location.state}`}
              onClose={this.closeMessageSentProvider.bind(this)}></AlertMessage>
          )}

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={!this.state.loading && this.closeModal.bind(this)}
            className="modal-dialog fadeInDown modal-lg"
            contentLabel="Importar pedidos">
            <div className="modal-content text-center">
              <div className="modal-header">
                <span className="import-title">Importar pedidos</span>
                <button
                  type="button"
                  className="close"
                  onClick={!this.state.loading && this.closeModal.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form method="post" action="" encType="multipart/form-data">
                  <div className="custom-control custom-switch d-flex left-padding-switch">
                    <input
                      type="checkbox"
                      defaultChecked
                      className="custom-control-input text-info"
                      id="verifyDeliveryNro"
                      value={this.state.verifyDeliveryNro}
                      onChange={this.handleDeliverySwitch.bind(this)}
                      disabled={this.state.loading}
                    />
                    <label
                      className="custom-control-label text-primary"
                      htmlFor="verifyDeliveryNro">
                      Diferenciación por número de delivery
                    </label>
                  </div>

                  <div className="form-group">
                    <div className="input-group mb-2 mt-4">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            disabled={this.state.loading}
                            type="file"
                            name="file"
                            id="file"
                            className="custom-file-input"
                            onChange={this.onChangeFile.bind(this)}
                          />
                          <label
                            children={
                              this.state.newRequests
                                ? this.state.newRequests.name
                                : 'Elige un archivo'
                            }
                            disabled={this.state.loading}
                            className="custom-file-label text-info"
                            for="file-input"
                            data-browse="Buscar"
                          />
                        </div>
                        <div class="input-group-append">
                          {!this.state.loading && (
                            <a
                              href="/docs/modelo_para_pedidos.csv"
                              className="btn btn-outline-secondary"
                              target="_blank"
                              type="button">
                              <i className="fas fa-file-alt"></i> Archivo modelo
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.loading && <ProgressBar />}

                  <button
                    type="button"
                    className="btn btn-info btn-lg btn-block mt-4"
                    id="upload"
                    onClick={this.importRequests.bind(this)}
                    disabled={!this.state.haveDocumentImport}>
                    {this.state.loading
                      ? 'Importando pedidos...'
                      : 'Importar pedidos'}{' '}
                    {this.state.loading && (
                      <span
                        className="spinner-border spinner-border-sm p-2 ml-3"
                        role="status"
                        aria-hidden="true"></span>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </Modal>
          {modalImportSuccess}
          {importAlert}

          <Modal
            isOpen={this.state.duplicatesModalIsOpen}
            // onRequestClose={this.closeduplicatesModal.bind(this)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                padding: 0,
                transform: 'translate(-50%, -50%)',
              },
            }}>
            <div className="modal-content text-center">
              <div>
                <h2 className="import-title some-top-air">Atención</h2>
              </div>
              <div className="modal-body">
                <AlertMessage type="warning" title="Pedidos duplicados" />
                <button
                  className="button-delete-modal btn btn-warning some-top-air"
                  type="button"
                  onClick={this.closeduplicatesModal.bind(this)}>
                  {' '}
                  Aceptar
                </button>
              </div>
            </div>
          </Modal>

          {this.state.loadingRequests ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '14%' }}>
                      Numero de pedido
                    </th>
                    <th scope="col" style={{ width: '14%' }}>
                      Numero de entrega
                    </th>
                    <th scope="col" style={{ width: '14%' }}>
                      Fecha de venta
                    </th>
                    <th scope="col" style={{ width: '14%' }}>
                      Fecha de impresión
                    </th>
                    <th scope="col" style={{ width: '10%' }}>
                      Peso estimado
                    </th>
                    <th scope="col" style={{ width: '27%' }}>
                      Observaciones
                    </th>
                    <th scope="col" style={{ width: '7%' }}>
                      Editar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.requests.map(request => {
                    const estimatedWeight = formatter.getEstimatedWeight(
                      request,
                      this.state.boxOptions,
                    );
                    const actionColumn = (
                      <td>
                        <div className="text-center">
                          <Link
                            to={`${process.env.PUBLIC_URL}/request/${request.id}`}>
                            <button
                              type="button"
                              className="btn btn-primary btn-circle">
                              <span className="fa fa-edit"></span>
                            </button>
                          </Link>
                        </div>
                      </td>
                    );
                    let cancelRequest;
                    request.cancelled
                      ? (cancelRequest = '(Cancelado)')
                      : (cancelRequest = '');

                    return (
                      <tr key={request.id}>
                        <td>{request.order_number + cancelRequest}</td>
                        <td>{request.delivery_number}</td>
                        <td>{formatter.formatDate(request.sale_date)}</td>
                        <td>{formatter.formatDate(request.print_date)}</td>
                        <td>{estimatedWeight}</td>
                        <td>
                          {
                            <ProductRequestValidation
                              render={(
                                addressValidation,
                                postalCodeValidation,
                                productsWeightValidation,
                                weightValidation,
                                sentToProviderValidation,
                                pickingValidation,
                                withdrawPickupNoteValidation,
                                deliveryStateValidation,
                              ) => {
                                return (
                                  <div>
                                    <span
                                      className={
                                        'fa fa-home ' +
                                        addressValidation(request).class
                                      }
                                      data-tip
                                      data-for={request.id + 'address'}>
                                      <Tooltip
                                        id={request.id + 'address'}
                                        tooltipText={
                                          addressValidation(request).text
                                        }
                                      />
                                    </span>

                                    <span
                                      className={
                                        'fa fa-mail-bulk ml-3 ' +
                                        postalCodeValidation(request).class
                                      }
                                      data-tip
                                      data-for={request.id + 'postalCode'}>
                                      <Tooltip
                                        id={request.id + 'postalCode'}
                                        tooltipText={
                                          postalCodeValidation(request)
                                            .textTooltip
                                        }
                                      />
                                    </span>

                                    {/*
                                    <span
                                      className={
                                        'fa fa-box ml-3 ' +
                                        productsWeightValidation(request).class
                                      }
                                      data-tip
                                      data-for={request.id + 'productsWeight'}>
                                      <Tooltip
                                        id={request.id + 'productsWeight'}
                                        tooltipText={
                                          productsWeightValidation(request)
                                            .textTooltip
                                        }
                                      />
                                    </span>

                                    <span
                                      className={
                                        'fa fa-weight ml-3 ' +
                                        weightValidation(
                                          request,
                                          this.state.boxOptions,
                                        ).class
                                      }
                                      data-tip
                                      data-for={request.id + 'weight'}>
                                      <Tooltip
                                        id={request.id + 'weight'}
                                        tooltipText={
                                          weightValidation(
                                            request,
                                            this.state.boxOptions,
                                          ).text
                                        }
                                      />
                                    </span>
                                    {config.validatePicking.active && (
                                      <span
                                        className={
                                          'fas fa-cubes ml-3 ' +
                                          pickingValidation(request).class
                                        }
                                        data-tip
                                        data-for={request.id + 'picking'}>
                                        <Tooltip
                                          id={request.id + 'picking'}
                                          tooltipText={
                                            pickingValidation(request).text
                                          }
                                        />
                                      </span>
                                    )}
                                    */}

                                    <span
                                      className={
                                        'fa fa-envelope ml-3 ' +
                                        sentToProviderValidation(request).class
                                      }
                                      data-tip
                                      data-for={
                                        request.id + 'sent_to_provider'
                                      }>
                                      <Tooltip
                                        id={request.id + 'sent_to_provider'}
                                        tooltipText={
                                          sentToProviderValidation(request).text
                                        }
                                      />
                                    </span>
                                    {request.provider?.isPickupPoint && (
                                      <span
                                        onClick={() => {
                                          if (
                                            withdrawPickupNoteValidation(
                                              request,
                                            ).clickable
                                          ) {
                                            this.redirectOpenFile(request.id);
                                          }
                                        }}
                                        style={{
                                          cursor:
                                            withdrawPickupNoteValidation(
                                              request,
                                            ).cursor,
                                        }}
                                        className={
                                          'fa fa-clipboard ml-3 cursor-pointer ' +
                                          withdrawPickupNoteValidation(request)
                                            .class
                                        }
                                        data-tip
                                        data-for={
                                          request.id + 'withdraw_pickup_note'
                                        }>
                                        <Tooltip
                                          id={
                                            request.id + 'withdraw_pickup_note'
                                          }
                                          tooltipText={
                                            withdrawPickupNoteValidation(
                                              request,
                                            ).text
                                          }
                                        />
                                      </span>
                                    )}
                                    {config.validateDelivery &&
                                      config.validateDelivery.active && (
                                        <span
                                          className={
                                            'fas fa-truck ml-3 ' +
                                            deliveryStateValidation(request)
                                              .class
                                          }
                                          data-tip
                                          data-for={
                                            request.id + 'delivery_state'
                                          }>
                                          <Tooltip
                                            id={request.id + 'delivery_state'}
                                            tooltipText={
                                              deliveryStateValidation(request)
                                                .text
                                            }
                                          />
                                        </span>
                                      )}
                                  </div>
                                );
                              }}
                            />
                          }
                        </td>
                        {actionColumn}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {!this.state.loadingRequests && (
          <div className="text-center">
            <Pagination
              onChangePage={this.handleChangePage.bind(this)}
              currentPage={this.state.currentPage}
              totalPages={this.getNumberOfPages()}
            />
          </div>
        )}
        <iframe className="pdf-frame" id="pdf-frame"></iframe>
        <Footer />
      </div>
    );
  }
}
