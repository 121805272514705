module.exports.backend = {
    apiUrl: "https://pickingar.venturing.com.ar/vPicking/api",
    wsUrl: "wss://pickingar.venturing.com.ar",
    wsPath: "/vPicking/ws/"
};

module.exports.weight = {
    numberOfPercent : 1.5,
    toleranceValue: 0.013,
};

module.exports.sizeOfPdf = {
    width: 425 ,
    high: 610,
};

module.exports.countryVersion = {
    currentCountry: 'AR', // AR - CL - MX - CO - PE
    pickUpFeatures: true,
    noPostalCode: false,
    backLogDashboard: false,
    linkLabelsForPP: false,
    vpicking_integrations: [
        {
            countryName: 'Chile',
            url: 'pickingcl.venturing.com.ar'
        }
    ]
}

module.exports.defaultBox = {
    size: 0
};

module.exports.giftsFeature = {
  active: true
};

module.exports.validatePicking = {
  active: true
};

module.exports.pickUp = {
    maximum_days_of_storage: 0,
};

module.exports.validateDelivery = {
  active: true
}
